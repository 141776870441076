// https://getbootstrap.com/docs/5.3/components/scrollspy/#nested-nav
import { Helmet } from 'react-helmet';

import "./About.css";
import { useCallback, useEffect, useRef, useState } from 'react';
import { getOrLoad } from '../lib/common';
import * as bootstrap from "bootstrap";

function shouldNavbar(width: number, height: number) {
  if (width < 700) {
    return true;
  }
  if (height < 600) {
    return true;
  }
  const ratio = width / height;
  if (height >= 600 && ratio > 1) {
    return false;
  }
  return ratio > 2 || ratio < 0.875;
};

async function send_contact_form(p1: {
  k: void | string;
  fn: string;
  ln: string;
  e: string;
  w: number;
  m: string;
}): Promise<{ s: number; e: string[]; }> {
  const v1 = await getOrLoad();
  let v2: {
    k: void | string;
    fn: string;
    ln: string;
    e: string;
    w: number;
    m: string;
  } = p1;
  v2.k = v1.session_key;
  const v3 = await fetch(v1.api_url + "/client/about/contact_submit", {
    "method": "POST",
    "body": JSON.stringify(v2)
  });
  if (!v3.ok) {
    return { s: 2, e: [] };
  }
  return await v3.json();
};
// https://getbootstrap.com/docs/5.3/components/modal/#live-demo

const About = () => {
  const [isNavbar, setNavbar] = useState(true);// default: true !important; false => menu, true => navbar
  const [hasContact, setContact] = useState(true);// true => show contact from, false => show contact sent success
  const navbar2_instance = useRef(null);
  const section_instance = useRef(null);
  let scrollSpy: React.MutableRefObject<bootstrap.ScrollSpy | undefined> = useRef();
  let collapse: React.MutableRefObject<bootstrap.Collapse | undefined> = useRef();
  const Navbar3CollapseStatus = useRef(false);// area-expand
  let modal_opened: React.MutableRefObject<bootstrap.Modal | undefined> = useRef();

  const onWindowResize = useCallback(() => {
    if (collapse.current instanceof bootstrap.Collapse) {
      collapse.current.dispose();
      collapse.current = undefined;
    }
    const v3 = shouldNavbar(window.innerWidth, window.innerHeight);
    if (v3 !== isNavbar) {
      setTimeout(onWindowResize, 5);
    }
    setNavbar(v3);
    if (v3) {
      if (null != navbar2_instance.current && null != section_instance.current) {
        section_instance.current.style.marginTop = ('' + navbar2_instance.current.offsetHeight) + "px";
      }
    } else {
      if (null != section_instance.current) {
        section_instance.current.style.marginTop = "0px";
      }
    }

    if (undefined !== scrollSpy.current) {
      scrollSpy.current.refresh();
    }
    setTimeout(() => {
      if (undefined !== scrollSpy.current) {
        scrollSpy.current.refresh();
      }
    }, 200);
  }, [collapse, scrollSpy, isNavbar, setNavbar, section_instance, navbar2_instance]);

  const onNavbar3CollapseButtonCb = () => {
    if (!(collapse.current instanceof bootstrap.Collapse)) {
      const v1 = document.getElementById('navbar3-listener');
      if (v1 instanceof HTMLElement) {
        collapse.current = new bootstrap.Collapse(v1, {
          toggle: false, // Prevent automatic toggle
        });
      } else {
        // not allowed here
      }
    }
    if (isNavbar && collapse.current instanceof bootstrap.Collapse) {
      if (Navbar3CollapseStatus.current) {
        collapse.current.hide();
        Navbar3CollapseStatus.current = false;
      } else {
        collapse.current.show();
        Navbar3CollapseStatus.current = true;
      }
    } else {
      // not allowed here
    }
  };

  const submitContact = () => {
    const v2: null | HTMLElement = document.getElementById('s-7-f_submit');
    if (v2 instanceof HTMLButtonElement) {
      v2.disabled = true;
    }
    send_contact_form({
      k: undefined,
      fn: document.getElementById('s-7-f_fn')?.value,
      ln: document.getElementById('s-7-f_ln')?.value,
      e: document.getElementById('s-7-f_e')?.value,
      w: parseInt(document.getElementById('s-7-f_w')?.value),
      m: document.getElementById('s-7-f_m')?.value
    }).then((v1) => {
      if (0 === v1.s) {
        setContact(false);
      } else {
        const v3: Map<string, string> = new Map([
          ["fn", 's-7-f_fn'],
          ["ln", 's-7-f_ln'],
          ["e", 's-7-f_e'],
          ["w", 's-7-f_w'],
          ["m", 's-7-f_m']
        ]);
        for (const [v4, v5] of v3) {
          const v6 = document.getElementById(v5);
          if (!(v6 instanceof HTMLElement)) {
            continue;
          }
          if (v1.e.includes(v4)) {
            v6.classList.add('is-invalid');
            v6.classList.remove('is-valid');
          } else {
            v6.classList.remove('is-invalid');
            v6.classList.add('is-valid');
          }
        }
        if (v2 instanceof HTMLButtonElement) {
          v2.disabled = false;
        }
      }
    });
  };

  function s_2_m_0_open(p1: string) {
    if (modal_opened instanceof bootstrap.Modal) {
      modal_opened.dispose();// close current
    }
    // eslint-disable-next-line
    modal_opened.current = new bootstrap.Modal(p1, {
      backdrop: true,
      focus: true
    });
    modal_opened.current.show();
  };

  function s_2_m_1_open() {
    s_2_m_0_open('#s-2-m-1');
  };

  function s_2_m_2_open() {
    s_2_m_0_open('#s-2-m-2');
  };

  function s_2_m_3_open() {
    s_2_m_0_open('#s-2-m-3');
  };

  useEffect(() => {
    if (undefined !== scrollSpy.current) {
      scrollSpy.current.dispose();
    }
    // eslint-disable-next-line
    scrollSpy.current = new bootstrap.ScrollSpy(document.body, {
      target: '#navbar3-listener',
    });

    {
      if (undefined !== collapse.current) {
        collapse.current.dispose();
      }
      const v1 = document.getElementById('navbar3-listener');
      if (v1 instanceof HTMLElement) {
        // eslint-disable-next-line
        collapse.current = new bootstrap.Collapse(v1, {
          toggle: false, // Prevent automatic toggle
        });
      }
    }

    console.log("loaded");

    window.addEventListener('resize', onWindowResize);
    onWindowResize();
    return () => {
      window.removeEventListener('resize', onWindowResize);
      if (scrollSpy.current instanceof bootstrap.ScrollSpy) {
        scrollSpy.current.dispose();
        scrollSpy.current = undefined;
      }

      if (collapse.current instanceof bootstrap.Collapse) {
        collapse.current.dispose();
        collapse.current = undefined;
      }
      if (modal_opened.current instanceof bootstrap.Modal) {
        modal_opened.current.dispose();// close opened
        modal_opened.current = undefined;
      }
    };
  }, [onWindowResize]);

  return (<>
    <Helmet>
      <link rel="icon" href="/assets/avatar_self.svg" />
      <link rel="apple-touch-icon" href="/assets/avatar_self.svg" />
      <meta property="og:image" content="/assets/avatar_self.svg" />
      <meta property="twitter:image" content="/assets/avatar_self.svg" />

      <title>Steve Shire Resume</title>
      <meta property="og:title" content="title" />

      <meta name="description" content="description" />
      <meta property="og:description" content="description" />

      <meta name="theme-color" content="#7f7f7f" />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>

    <div className="row g-0">

      <nav ref={navbar2_instance} className={"navbar-dark " + (isNavbar ?
        "navbar container-fluid navbar-expand-lg za_navbar2_0 fixed-top" :
        "za_navbar1_0 justify-content-center align-items-center d-flex flex-column")}>
        <a href="#s" className={"navbar-brand " + (isNavbar ?
          "za_navbar2_0_left_end" :
          "justify-content-center align-items-center d-flex flex-column d-none")}>
          <img src="/assets/avatar_self.svg" alt="Avatar" className={(isNavbar ?
            "za_navbar2_img1_1" :
            "za_navbar1_img1_1 d-none")
            + " img-fluid rounded-circle"} />
        </a>
        <button id="navbar3-collapse-button" onClick={onNavbar3CollapseButtonCb}
          className={"navbar-toggler " + (isNavbar ? "za_navbar2_0_right_end" : "d-none")}>
          {/*aria-expanded="false" data-bs-target="#navbar3-listener" data-bs-toggle="collapse" */}
          <span className="navbar-toggler-icon"></span>
        </button>
        <nav className={(isNavbar ?
          "collapse navbar-collapse za_navbar2_0_right_end" :
          "flex-column")} id="navbar3-listener">
          <nav className={"nav nav-pills " + (isNavbar ?
            "navbar-nav ms-auto" :
            "flex-column justify-content-center align-items-center")}>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-1">Steve Shire</a>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-2">Projects &amp; Portfolio</a>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-3">About</a>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-4">Education</a>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-5">Skills</a>
            <a className={(isNavbar ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-7">Contacts</a>
          </nav>
        </nav>
      </nav>

      <div className={isNavbar ? "" : "za_sbase_navleft"} ref={section_instance}>
        <div data-bs-spy="scroll" data-bs-target="#navbar3-listener" data-bs-smooth-scroll="true" tabIndex={0} id="s">

          <div id="s-1" className="container za_section justify-content-center align-items-center d-flex flex-column za_item_background-color_1">
            <img src="/assets/avatar_self.svg" alt="Avatar" className="s-1-i-avatar img-fluid" />
            <h1 className="display-5 za_font_art_5 fst-italic fw-bold za_item_color_darkslateblue">Steve Shire</h1>
            <div className="za_item_color_darkslateblue s-1-divider-l0">
              <div className="s-1-divider-l1p1"></div>
              <div className="s-1-divider-l1p2">
                <i className="bi bi-person-fill"></i>
              </div>
              <div className="s-1-divider-l1p3"></div>

            </div>
            <p className="za_item_color_darkslateblue za_font-size_1-25rem">Software Engineer | Full-Stack Web Developer</p>
            <p className="za_item_color_darkslateblue za_font-size_1-25rem za_font-weight_800 za_font-size_1-25rem">Creator of the <a className="za_item_color_darkslateblue" href="https://suud.net/">SUUD Project</a>.</p>
          </div>

          <div id="s-2" className="container za_section">
            <p className="text-center fs-1 za_font_art_3" id="s-2-main-header">Projects &amp; Portfolio</p>

            <div className="w-100 d-flex flex-column align-items-center justify-content-center">

              <div className="w-50 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_1_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">SUUD Project</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Mar 2024 - Sep 2024</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">My 1st project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_purple s-2-proj-prev-lp0"></div>
              </div>

              <div className="w-50 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_2_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">HW &amp; YZ Official Site</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Aug 2024 - Sep 2024</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">My 2nd project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_gold s-2-proj-prev-lp0"></div>
              </div>

              <div className="w-50 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_3_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">Improved CyNER</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Aug 2023 - Dec 2023</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">Yet another project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_darkcyan s-2-proj-prev-lp0"></div>
              </div>

            </div>
          </div>

          <div id="s-3" className="container za_section za_item_background-color_2">
            <h4>Item 3</h4>
            <p>...</p>
            <h4>Item 3</h4>
            <p>...</p>
            <h4>Item 3</h4>
            <p>...</p>
            <h4>Item 3</h4>
            <p>...</p>
          </div>

          <div id="s-4" className="container za_section">
            <h4>Item 4</h4>
            <p>...</p>
            <i className="bi bi-check2"></i>
            <h4>Item 4</h4>
            <p>...</p>
            <h4>Item 4</h4>
            <p>...</p>
            <h4>Item 4</h4>
            <p>...</p>





          </div>

          <div id="s-5" className="container za_section za_item_background-color_3">
            <h4>Item 5</h4>
            <p>...</p>
            <h4>Item 5</h4>
            <p>...</p>
            <h4>Item 5</h4>
            <p>...</p>
            <h4>Item 5</h4>
            <p>...</p>

            <i className="bi bi-browser-firefox"></i>
            <i className="bi bi-browser-chrome"></i>
            <i className="bi bi-browser-edge"></i>

          </div>

          <div id="s-7" className="container za_section za_item_background-color_4">
            <div className="text-center">
              <h1 id="s-7-1-h1" className="za_font_art_3">Contact Me</h1>
            </div>

            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="card mt-2 mx-auto p-4 za_s-7-4-card za_item_border_none za_item_background-color_none">
                  <div className="card-body">

                    <div className="container">
                      <div id="s-7-f" role="form" hidden={!hasContact} className={hasContact ? "" : "d-none"}>
                        <div className="controls">

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group form-floating">
                                <input id="s-7-f_fn" type="text" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                                  placeholder="" data-error="First name is required." />
                                <label htmlFor="s-7-f_fn">First Name *</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group form-floating">
                                <input id="s-7-f_ln" type="text" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                                  placeholder="" data-error="Last name is required." />
                                <label htmlFor="s-7-f_ln">Last Name *</label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group form-floating">
                                <input id="s-7-f_e" type="email" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                                  placeholder="" data-error="Valid email is required." />
                                <label htmlFor="s-7-f_e">Email *</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group form-floating">
                                <select id="s-7-f_w" className="form-select s-7-f-common bsor_label_bg-transparent" required={true} defaultValue={0}
                                  data-error="">
                                  <option value={0} disabled className="za_color_transparent_grey">--Select--</option>
                                  <option value={2}>Resume</option>
                                  <option value={3}>HW & YZ LLP</option>
                                  <option value={4}>POSHIM LLC</option>
                                  <option value={5}>BYARTS LIMITED</option>
                                  <option value={1}>Other</option>
                                </select>
                                <label htmlFor="s-7-f_w">How did you find me? *</label>
                              </div>
                            </div>

                            <div className="col-md-12 za_s-7-5">
                              <div className="form-group form-floating">
                                <textarea id="s-7-f_m" className="form-control s-7-f-common za_item_min-height_10rem bsor_label_bg-transparent" rows={4} required={true}
                                  placeholder="" data-error="Please, leave me a message."></textarea>
                                <label htmlFor="s-7-f_m">Message *</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button id="s-7-f_submit" onClick={submitContact}
                                className="btn btn-send btn-outline-primary za_s-7-3-btn pt-2 btn-block">Send Message</button>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div id="s-7-f" role="form" hidden={hasContact} className={hasContact ? "d-none" :
                        "d-flex flex-column justify-content-center align-items-center za_item_border_1"}>
                        <h2 className="za_font_art_1">I have received your message.</h2><br />
                        <h1 className="za_font_art_5">Thanks for your contact.</h1>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div className="row s-7-e">
              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <i className="bi bi-geo-alt"></i><span>Perth, Australia</span>
              </div>

              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="fs-4">Around the Web</p>
                <div className="d-flex justify-content-center align-items-center s-7-f-4-l-2">
                  <a className="btn btn-outline-dark d-flex s-7-f-2-a-1" href="https://www.linkedin.com/in/steve-shire-8767942b6/">
                    <i className="bi bi-linkedin s-7-f-2-ico-1"></i>
                  </a>
                  <a className="btn btn-outline-dark d-flex s-7-f-2-a-1" href="mailto:314159265358x@gmail.com">
                    <i className="bi bi-envelope-at s-7-f-2-ico-1"></i>
                  </a>
                  <a hidden={true} href="https://github.com/"><i className="bi bi-github s-7-f-2-ico-1 d-none"></i></a>
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="fs-4">About this website</p>
                <div className="fs-6 s-7-f-4-l-2">
                  <p>
                  <span >This website is powered by </span>
                  <img src="/assets/external/techicons.dev/DotNET.svg" alt="" title=".NET 8.0" className="s_7_f_3_ico_max-size" />
                  <img src="/assets/external/techicons.dev/React.svg" alt="" title="React 18.3" className="s_7_f_3_ico_max-size" />
                  <img src="/assets/external/techicons.dev/Bootstrap.svg" alt="" title="Bootstrap 5.3" className="s_7_f_3_ico_max-size" />
                  <img src="/assets/external/techicons.dev/MongoDB.svg" alt="" title="MongoDB 8.0" className="s_7_f_3_ico_max-size" />
                  <span>.</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center">
              <small>Copyright © Steve Shire 2024</small>
            </div>
          </div>

        </div>
      </div>
    </div >

    <div className="za_modal_hidden_area">

      <div className="modal fade" id="s-2-m-1" tabIndex={-1} aria-labelledby="s-2-m-1-h" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="s-2-m-1-h">Simplified Universal User Dashboard</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
                <span>Developed, tested, deployed, and maintained a Single Sign-On (SSO) website using the Nuxt.js framework.</span>
                <a href='https://suud.net'><i className="bi bi-link-45deg"></i></a>
              </p>
              <p>Ensured high levels of security and performance across both front-end and back-end layers, utilizing modern web technologies.</p>
            </div>
            <div className="modal-footer d-none">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>

      <div className="za_modal_hidden_area">
        <div className="modal fade" id="s-2-m-2" tabIndex={-1} aria-labelledby="s-2-m-2-h" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="s-2-m-2-h">HW &amp; YZ LLP</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>
                  <span>Contributed to the official website&apos;s backend architecture, focusing on secure API integrations and seamless data flow.</span>
                  <a href='https://hwyz.uk'><i className="bi bi-link-45deg"></i></a>
                </p>
              </div>
              <div className="modal-footer d-none">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="za_modal_hidden_area">
        <div className="modal fade" id="s-2-m-3" tabIndex={-1} aria-labelledby="s-2-m-3-h" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="s-2-m-3-h">Improved CyNER</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Inproved CyNER system &lpar;Cybersecurity Named Entity Recognition&rpar;.</p>
                <p>
                  <span>Based on customized Bi-LSTM PyTorch model and other existing pretrained TensorFlow models with improvements.</span>
                  <a href='https://github.com/314159265358x/ECUCyNER'><i className="bi bi-link-45deg"></i></a>
                </p>
              </div>
              <div className="modal-footer d-none">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>







    </div>












  </>);
};

export default About;
