// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.zx_page_index_l5_intro {
    position: relative;
    z-index: 1;
    margin: auto;
    max-width: 540px;
    text-align: center;
    font-size: xx-large;
    color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Index.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["\n.zx_page_index_l5_intro {\n    position: relative;\n    z-index: 1;\n    margin: auto;\n    max-width: 540px;\n    text-align: center;\n    font-size: xx-large;\n    color: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
