import { useState } from "react";
import { ClientCommon, ClientCommonEvent } from "./common";
import { LangFile } from "./lang_file";

export class LangDelivrCtState {
    key: void | string = undefined;
    readonly state: [string, React.Dispatch<React.SetStateAction<string>>] = useState("");

    onUpdate(p1: void | LangFile) {
        if (undefined === p1) {
            this.state[1](this.key || "");
        } else {
            this.state[1](p1.translate(this.key || "") || this.key || "");
        }
    }
};

export class LangDelivrCt {
    client_common: void | ClientCommon = undefined;
    listener: void | ((p1: ClientCommonEvent) => void) = undefined;
    translate_states: Map<string, LangDelivrCtState> = new Map();

    initialize(p1: ClientCommon) {
        if (undefined !== this.listener) {
            if (undefined === this.client_common) {
                this.client_common = p1;
            }
            this.finalize();
        }
        this.client_common = p1;
        const v1 = this;
        this.listener = (p1: ClientCommonEvent) => {
            v1.onLangChange(p1.cc.lang_current);
        };
        this.client_common.callback_eventbus.on('langchange', this.listener);
        if (this.client_common.lang_current instanceof LangFile) {
            this.onLangChange(this.client_common.lang_current);
        }
    }

    finalize() {
        if (undefined !== this.client_common && undefined !== this.listener) {
            this.client_common.callback_eventbus.off('langchange', this.listener);
            this.client_common = undefined;
            this.listener = undefined;
        }
    }

    onLangChange(p1: void | LangFile) {
        for (const [, v2] of this.translate_states) {
            v2.onUpdate(p1);
        }
    }

    keyof(p1: string): string {
        if (typeof p1 != "string" || p1.length === 0) {
            throw new TypeError("null pointer of argument");
        }
        let v1 = this.translate_states.get(p1);
        if (undefined !== v1) {
            return v1.state[0];
        }
        v1 = new LangDelivrCtState();
        v1.key = p1;
        this.translate_states.set(p1, v1);
        return v1.state[0];
    }

    forceLoad(p1: string[]) {
        for (const v1 of p1) {
            this.keyof(v1);
        }
    }

};
