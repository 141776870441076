
import React, { useEffect, useRef } from 'react';
import NavBar from '../components/navbar';
import './Home.css';
import '../Background.css';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import { getOrLoad } from '../lib/common';
import { ArrowRepeat, PencilSquare, Save } from 'react-bootstrap-icons';

async function notebook_fetch() {
  const v1 = await getOrLoad();
  const v2 = v1.session_key;
  if (null == v2 || v2.length === 0) {
    return undefined;
  }
  const v3 = await fetch(v1.api_url + "/client/user/get_notebook", {
    "method": "POST",
    "body": v2
  })
  if (!v3.ok) {
    return undefined;
  }
  return await v3.text();
};

async function notebook_store(p1: string) {
  const v1 = await getOrLoad();
  const v2 = v1.session_key;
  if (null == v2 || v2.length === 0) {
    return false;
  }
  const v3 = await fetch(v1.api_url + "/client/user/set_notebook", {
    "method": "POST",
    "body": JSON.stringify({
      k: v2,
      t: p1
    })
  })
  return v3.ok;
};

const Home = () => {
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  const notebook_data = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    getOrLoad().then((v1) => {
      t.initialize(v1);
      if (null == v1.user_baseinfo) {
        window.location.replace("/");
      }

    });
    return () => {
      t.finalize();
    }
  }, [t]);

  const notebook_reset = () => {
    notebook_fetch().then((v1) => {
      if (typeof v1 != "string" || v1.length === 0) {
        return;
      }
      if (notebook_data.current) {
        notebook_data.current.value = v1;
      }
    });
  };

  const notebook_save = () => {
    if (notebook_data.current) {
      notebook_store(notebook_data.current.value).then((v1) => {
        if (v1) {

        } else {

        }
      });
    }
  };

  notebook_reset();

  return (
    <div className="bg-image">
      <NavBar />
      
      <main className="d-flex g-0 zx_page_l2_main page-container">

        <div className="col-md-4 col-12">
          <div className="card card-message text-center">
            <div className="card-header bg-primary text-white">
              <PencilSquare className="me-2" />
              {t.keyof("Notebook")}
            </div>
            <div className="card-body scroll">
              <textarea className="form-control" id="notebook_input" ref={notebook_data}></textarea>
            </div>
            <div className="card-footer">
              <button className="btn btn-outline-danger me-2" onClick={notebook_reset}>
                <ArrowRepeat className="me-1" />
                {t.keyof("Reset")}
              </button>
              <button className="btn btn-outline-primary" onClick={notebook_save}>
                <Save className="me-1" />
                {t.keyof("Save")}
              </button>
            </div>
          </div>
        </div>


      </main>
    </div>
  );
};

export default Home;