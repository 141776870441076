import React, { useEffect } from 'react';
import NavBar from '../components/navbar';
import './Index.css';
import '../Background.css';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import { getOrLoad } from '../lib/common';

const Index = () => {
  // eslint-disable-next-line
  const t = new LangDelivrCt();

  useEffect(() => {
    getOrLoad().then((v1) => {
      t.initialize(v1);
    });
    return () => {
      t.finalize();
    }
  }, [t]);

  return (
    <main className="bg-image d-flex align-items-center">
      <div className="fixed-top">
        <NavBar />
      </div>

      <div className="container zx_page_index_l5_intro">
          <h1>{t.keyof("Index Page Header")}</h1>
          <span>{t.keyof("Index Page Body")}</span>
      </div>
    </main>
  );
};

export default Index;