// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#notebook_input {
    width: 100%;
    min-height: 8em; /* Minimum height of 4 lines */
    max-height: 16em; /* Minimum height of 4 lines */
    height: auto;
    resize: vertical; /* Allow vertical resizing */
}

.zx_page_l2_main{
    padding: 4px 4px;

    display: flex;
    flex-wrap: wrap;

    padding: 0;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,eAAe,EAAE,8BAA8B;IAC/C,gBAAgB,EAAE,8BAA8B;IAChD,YAAY;IACZ,gBAAgB,EAAE,4BAA4B;AAClD;;AAEA;IACI,gBAAgB;;IAEhB,aAAa;IACb,eAAe;;IAEf,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["\n#notebook_input {\n    width: 100%;\n    min-height: 8em; /* Minimum height of 4 lines */\n    max-height: 16em; /* Minimum height of 4 lines */\n    height: auto;\n    resize: vertical; /* Allow vertical resizing */\n}\n\n.zx_page_l2_main{\n    padding: 4px 4px;\n\n    display: flex;\n    flex-wrap: wrap;\n\n    padding: 0;\n    height: 100vh;\n    min-height: 100vh;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
