// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-image {
    padding: 0;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100% auto;
    background-repeat: repeat;
    background-position: top center;
}
/*
@media only screen and (max-width:768px) {
    .bg-image {
        padding: 300px 0;
    }
}
*/`, "",{"version":3,"sources":["webpack://./src/Background.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,yDAAsD;IACtD,0BAA0B;IAC1B,yBAAyB;IACzB,+BAA+B;AACnC;AACA;;;;;;CAMC","sourcesContent":[".bg-image {\n    padding: 0;\n    height: 100vh;\n    min-height: 100vh;\n    overflow: hidden;\n    background-image: url('/public/assets/background.svg');\n    background-size: 100% auto;\n    background-repeat: repeat;\n    background-position: top center;\n}\n/*\n@media only screen and (max-width:768px) {\n    .bg-image {\n        padding: 300px 0;\n    }\n}\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
