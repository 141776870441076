
import React, { useEffect, useState } from 'react';
import { getOrLoad, sso_suud_start_login } from '../lib/common';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import "./navbar.css";

const do_sign_in = sso_suud_start_login;

const do_sign_out = () => {
  getOrLoad().then((v1) => {
    return v1.signout();
  }).then(() => {
    window.location.reload();
  });
};

const do_page_index = () => {
  window.location.assign("/");
};

const do_page_home = () => {
  window.location.assign("/home");
};

const NavBar = () => {
  const login_username = useState("");
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  //const [t, setT] = useState(new LangDelivrCt());
  //const t = instance_default;

  useEffect(() => {
    getOrLoad().then((v1) => {
      login_username[1](v1.user_name || "");
      t.initialize(v1);
    });
    return () => {
      t.finalize();
    }
  }, [login_username, t]);

  t.forceLoad([
    "Not logged in",
    "Sign In",
    "Home Page",
    "Welcome",
    "Sign Out"
  ]);

  return (
    <nav className="navbar container-fluid navbar-expand-md navbar-dark zx_com_navbar">
      <img className="navbar-brand nav-item zx_com_navbar_icon za_navbar2_0_left_end" src="/favicon.svg" alt="icon" onClick={do_page_index} />
      <button aria-expanded="false" className="navbar-toggler zx_com_navbar_right_end" data-bs-target="#navbarNavDropdown" data-bs-toggle="collapse">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse zx_com_navbar_right_end" id="navbarNavDropdown">
        <ul className="navbar-nav ms-auto">
          {login_username[0].length === 0 ? (<>
            <li className="nav-item"><span className="nav-link">{t.keyof("Not logged in")}</span></li>
            <li className="nav-item"><button className="btn btn-outline-primary" onClick={do_sign_in}>{t.keyof("Sign In")}</button></li>
          </>) : (<>
            <li className="nav-item"><span className="nav-link" onClick={do_page_home}>{t.keyof("Home Page")}</span></li>
            <li className="nav-item"><span className="nav-link">{t.keyof("Welcome")},&nbsp;{login_username[0]}</span></li>
            <li className="nav-item"><button className="btn btn-outline-primary" onClick={do_sign_out}>{t.keyof("Sign Out")}</button></li>
          </>)}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
