// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.zx_com_navbar{
  background-color: rgba(53, 51, 83,0.75);
  font-size: medium;
}

.zx_com_navbar_icon{
  max-width: 2rem;
}

.zx_com_navbar_right_end{
  margin-right: 1rem;
}

.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/components/navbar.css"],"names":[],"mappings":";AACA;EACE,uCAAuC;EACvC,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;AACf","sourcesContent":["\n.zx_com_navbar{\n  background-color: rgba(53, 51, 83,0.75);\n  font-size: medium;\n}\n\n.zx_com_navbar_icon{\n  max-width: 2rem;\n}\n\n.zx_com_navbar_right_end{\n  margin-right: 1rem;\n}\n\n.navbar-nav {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
